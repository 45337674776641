const theme = {
  colors: {
    primary: '#4688F1',
    base: 'hsla(0, 0%, 0%, 0.9)',
    grey: '#595C62',
  },
  maxWidth: '900px',
  maxWidthText: '650px',
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px',
  },
};

export default theme;
