import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Global, css } from "@emotion/core";
import { ThemeProvider } from 'emotion-theming';
import 'typeface-merriweather';
import 'typeface-montserrat';

import theme from 'styles/theme';
import Header from './header';
import Footer from './footer';

const globalStyles = (theme) => css`
  body {
    color: ${theme.colors.base};
  }

  a {
    color: ${theme.colors.primary};
    text-decoration: none;
    box-shadow: 0 1px 0 0 currentColor;
  }
  a:hover, a:active {
    box-shadow: none;
  }
}
`;

const Layout = ({ children, includeHeader=true, includeFooter=true, showHeaderHomeLink=true }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        { includeHeader ? (
          <Header
            siteTitle={data.site.siteMetadata.title}
            showHomeLink={showHeaderHomeLink}
          />
        ): null }

        <main>
          {children}
        </main>

        { includeFooter ? (
          <Footer/>
        ): null }
      </ThemeProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
