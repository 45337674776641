import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react';
import styled from '@emotion/styled';

import Wrapper from './wrapper';

const StyledHeader = styled.header`
  margin-bottom: 1.5rem;
`;

const StyledContentWrapper = styled(Wrapper)`
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
`;

const StyledHomeLink = styled(Link)`
  color: ${props => props.theme.colors.base};
  box-shadow: none;
  font-size: 1.2rem;
  font-weight: 400;
  font-style: normal;
`;

const StyledNav = styled.nav`
  margin-left: auto;

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;
      margin: 0;

      &:not(:last-of-type) {
        margin-right: 1.4rem;
      }
    }
  }
`;

const StyledNavLink = styled(Link)`
  font-size: 0.875rem;
  font-weight: 400;
  color: hsla(0, 0%, 0%, 0.8);
`;

const Header = ({ siteTitle, showHomeLink }) => (
  <StyledHeader>
    <StyledContentWrapper>
      { showHomeLink ? (
        <StyledHomeLink to="/">{siteTitle}</StyledHomeLink>
      ) : null }
      <StyledNav>
        <ul>
          <li>
            <StyledNavLink to="/blog/">Blog</StyledNavLink>
          </li>
          <li>
            <StyledNavLink to="/contact/">Contact</StyledNavLink>
          </li>
        </ul>
      </StyledNav>
    </StyledContentWrapper>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
