import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import _ from 'lodash';

const generateWebsiteSchema = ({ siteMetadata }) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: siteMetadata.title,
    url: siteMetadata.siteUrl,
    description: siteMetadata.description,
    author: {
      '@type': 'Person',
      name: siteMetadata.author,
    },
  };
};

const generateBlogPostBreadcrumbList = ({ siteMetadata, blogPost }) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Blog',
        item: `${siteMetadata.siteUrl}/blog/`,
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: blogPost.title,
        item: `${siteMetadata.siteUrl}${blogPost.path}`,
      },
    ],
  };
};

const generateBlogPostSchema = ({ siteMetadata, blogPost }) => {
  const { path, title, description, datePublished, dateModified, keywords } = blogPost;
  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    url: `${siteMetadata.siteUrl}${path}`,
    name: title,
    headline: title,
    keywords,
    description,
    publisher: {
      '@type': 'Person',
      name: siteMetadata.author,
      url: siteMetadata.siteUrl,
    },
    author: {
      '@type': 'Person',
      name: siteMetadata.author,
      url: siteMetadata.siteUrl,
    },
    mainEntityOfPage: `${siteMetadata.siteUrl}${path}`,
    datePublished,
    dateModified,
  };
};

function SEO({ title, titleTemplate, description, lang, keywords, path, type, blogPost, meta, script=[] }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const {
          site: {
            siteMetadata,
          },
        } = data;
        const metaDescription = description || siteMetadata.description;
        const url = `${siteMetadata.siteUrl}${path}`;

        const schemaOrgJSONLD = [
          generateWebsiteSchema({ siteMetadata }),
        ];
        if (blogPost) {
          schemaOrgJSONLD.push(generateBlogPostBreadcrumbList({siteMetadata, blogPost}));
          schemaOrgJSONLD.push(generateBlogPostSchema({siteMetadata, blogPost}));
        }

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={!_.isUndefined(titleTemplate) ? titleTemplate : `%s | ${siteMetadata.title}`}
            link={[
              {
                rel: 'canonical',
                href: url,
              },
            ]}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:url`,
                content: url,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: type,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: `@${siteMetadata.social.twitter.username}`,
              },
              {
                name: `twitter:title`,
                content: title,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                      name: `keywords`,
                      content: keywords.join(`, `),
                    }
                  : []
              )
              .concat(meta)
            }
            script={[
            ]
            .concat(
              schemaOrgJSONLD && schemaOrgJSONLD.length ?
                {
                  type: 'application/ld+json',
                  innerHTML: JSON.stringify(schemaOrgJSONLD)
                }
              : []
            )
            .concat(script)}
          />
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  type: 'website',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  type: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        social {
          twitter {
            username
          }
        }
      }
    }
  }
`
