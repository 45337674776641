import { graphql } from 'gatsby';
import React from 'react';
import styled from '@emotion/styled';
import moment from 'moment';

import SEO from '../components/seo';
import { Layout, Wrapper } from 'components/layout';

const PostMeta = styled.p`
  font-size: 0.8rem;
  margin: 0;
  margin-top: -1.2rem;
  margin-bottom: 1.666rem;

  a {
    font-style: normal;
  }
`;

const PostBody = styled.div`
  blockquote {
    margin-left: -1.666rem;
    margin-right: 1.666rem;
    padding-left: 1.354rem;
    border-left: 0.312rem solid ${props => props.theme.colors.primary};
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-style: italic;
    color: ${props => props.theme.colors.primary};
  }

  > code[class*="language-"],
  > p > code[class*="language-"] {
    background: rgba(255,229,100,0.25);
    background: #fbf2e9;
    color: ${props => props.theme.colors.base};
    padding: .15em .3em .15em;
    border-radius: .3em;
  }
`;

export default ({ data }) => {
  const post = data.markdownRemark;
  const {
    html,
    frontmatter: {
      title,
      date,
      excerpt,
      keywords,
    },
    fields: {
      slug,
    },
  } = post;

  const dateMomentObj = moment(date);

  return (
    <Layout>
      <SEO
        path={`/blog/${slug}/`}
        title={title}
        description={excerpt}
        keywords={keywords}
        blogPost={{
          path: `/blog/${slug}/`,
          title,
          description: excerpt,
          datePublished: dateMomentObj.toISOString(),
          dateModified: dateMomentObj.toISOString(),
          keywords,
        }}
      />
      <Wrapper>
        <h1>{ title }</h1>
        <PostMeta>
          <time dateTime={dateMomentObj.format('YYYY-MM-DD')}>
            Posted on { dateMomentObj.format('MMMM DD, YYYY')}
          </time>
        </PostMeta>
        <PostBody dangerouslySetInnerHTML={{ __html: html }} />
      </Wrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        excerpt
        keywords
      }
      fields {
        slug
      }
    }
  }
`;
