import React from 'react';
import styled from '@emotion/styled';

import Wrapper from './wrapper';

const StyledFooter = styled.footer`
  margin-top: 3rem;
`;

const StyledContentWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

const StyledText = styled.p`
  color: hsla(0, 0%, 0%, 0.8);
  font-size: 0.875rem;
  margin: 0;
`;

const Footer = () => (
  <StyledFooter>
    <StyledContentWrapper>
      <StyledText>
        &copy; {new Date().getFullYear()}
      </StyledText>
    </StyledContentWrapper>
  </StyledFooter>
)

export default Footer
